/* eslint-disable */
import LazyLoad from "vanilla-lazyload";
import inView from "in-view";

const HTML = document.querySelector("html");

class App {
  constructor() {
    this.lazyload = new LazyLoad({
      unobserve_entered: true,
      elements_selector: ".js-lazy",
      threshold: window.innerHeight * 0.8,
      // eslint-disable-next-line camelcase
      callback_enter: ($el) => {
        if ($el.classList.contains("js-lazy-error-placeholder")) {
          $el.lazyLoadPlaceholder = $el.src;
        } else if ($el.dataset.src == undefined) {
          const figure = $el.closest("figure");
          if (figure) figure.classList.add("img-is-loaded");
        }
      },
      callback_loaded: (el) => {
        const figure = el.closest("figure");
        if (figure) figure.classList.add("img-is-loaded");
      },
      // eslint-disable-next-line camelcase
      callback_error: ($el) => {
        if ($el.lazyLoadPlaceholder) {
          $el.src = $el.lazyLoadPlaceholder;
        }
      },
    });

    this.marqueeTxt();
    this.inViewport();
  }

  marqueeTxt() {
    const elts = document.querySelectorAll(".marquee-txt");
    const speed = 0.2;
    if (elts) {
      elts.forEach((el) => {
        const clone = el.innerHTML;
        const firstElement = el.children[0];
        let i = 0;

        el.insertAdjacentHTML("beforeend", clone);
        el.insertAdjacentHTML("beforeend", clone);

        setInterval(function () {
          firstElement.style.marginLeft = `-${i}px`;
          if (i > firstElement.clientWidth) {
            i = 0;
          }
          i += speed;
        }, 0);
      });
    }
  }

  inViewport() {
    // In viewport
    inView.offset({
      bottom: window.innerHeight * 0.1,
    });
    inView(".js-reveal:not(.is-visible)")
      .on("enter", function (elt) {
        elt.dispatchEvent(new Event("inview"));
        elt.classList.add("is-visible");
        elt.classList.remove("not-visible");

        const video = elt.querySelector("video");

        if (
          (elt.tagName == "VIDEO" && elt.dataset.autoplay == "true") ||
          (video && video.dataset.autoplay == "true")
        ) {
          const v = video || elt;
          const playPromise = v.play();
          if (playPromise !== null) {
            playPromise.catch(() => {
              v.play();
            });
          }
        }
      })
      .on("exit", function (elt) {
        const video = elt.querySelector("video");
        if (
          (elt.tagName == "VIDEO" && elt.dataset.autoplay != "") ||
          (video && video.dataset.autoplay != "")
        ) {
          const v = video || elt;
          v.pause();
        }

        elt.dispatchEvent(new Event("outview"));
        elt.classList.add("not-visible");
      });
  }
}

window.addEventListener("DOMContentLoaded", () => {
  new App();
});
